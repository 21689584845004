<template>
  <v-container fluid class="skill-create">
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
        <v-row v-if="false">
          <v-col md="4">
            <div class="col bg-light-warning ttbox rounded-xl mr-7">
              <span class="svg-icon svg-icon-3x svg-icon-warning my-2">
                <inline-svg :src="$assetURL('media/custom-svg/ticket.svg')" />
                <span class="font-weight-700 ml-2">Skills</span>
              </span>
              <span class="ttnumbr font-weight-700 mt-2" style="float: right">
                0
              </span>
            </div>
          </v-col>
          <v-col md="4">
            <div class="col bg-light-primary ttbox rounded-xl mr-7">
              <span class="svg-icon svg-icon-3x svg-icon-warning my-2">
                <inline-svg :src="$assetURL('media/custom-svg/ticket.svg')" />
                <span class="font-weight-700 ml-2">License</span>
              </span>
              <span class="ttnumbr font-weight-700 mt-2" style="float: right">
                0
              </span>
            </div>
          </v-col>
          <v-col md="4">
            <div class="col bg-light-danger ttbox rounded-xl mr-7">
              <span class="svg-icon svg-icon-3x svg-icon-warning my-2">
                <inline-svg :src="$assetURL('media/custom-svg/ticket.svg')" />
                <span class="font-weight-700 ml-2">Certificate</span>
              </span>
              <span class="ttnumbr font-weight-700 mt-2" style="float: right">
                0
              </span>
            </div>
          </v-col>
        </v-row>

        <v-row style="background: #ecedef" v-if="false">
          <v-col md="4">
            <div
              :class="'card m-0 top_card card-custom gutter-b border-primary'"
            >
              <div class="card-body p-0">
                <div
                  :class="'card-rounded-bottom mid_part shadow-sm bg-light-cyan bg-front-white'"
                >
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span
                      class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                    >
                      <!-- <span class="symbol-label">
                            <span
                              :class="'svg-icon svg-icon-xl svg-icon-white'"
                            >
                            <v-icon medium>mdi-download</v-icon>
                              <inline-svg
                                :src="
                                  $assetURL(
                                    'media/custom-svg/engineer.svg'
                                  )
                                "
                              />
                            </span>
                          </span> -->
                      <v-icon medium class="symbol-light-danger"
                        >mdi-certificate</v-icon
                      >
                      <span
                        class="text-dark-75 font-weight-bold mt-2"
                        style="margin-left: 20px"
                      >
                        Skills
                      </span>
                    </span>

                    <div class="d-flex flex-column text-right bTitle">
                      <a href="#">
                        <template>
                          <span
                            :class="'qnt_val font-weight-bolder text-primary'"
                          >
                            {{ summaryCerts.skill }}
                          </span>
                        </template>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="4">
            <div
              :class="'card m-0 top_card card-custom gutter-b border-primary'"
            >
              <div class="card-body p-0">
                <div
                  :class="'card-rounded-bottom mid_part shadow-sm bg-light-cyan bg-front-white'"
                >
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span
                      class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                    >
                      <!-- <span class="symbol-label">
                            <span
                              :class="'svg-icon svg-icon-xl svg-icon-white'"
                            >
                              <inline-svg
                                :src="
                                  $assetURL(
                                    'media/custom-svg/engineer.svg'
                                  )
                                "
                              />
                            </span>
                          </span> -->
                      <v-icon medium class="symbol-light-danger"
                        >mdi-certificate</v-icon
                      >
                      <span
                        class="text-dark-75 font-weight-bold mt-2"
                        style="margin-left: 20px"
                      >
                        Licenses
                      </span>
                    </span>

                    <div class="d-flex flex-column text-right bTitle">
                      <a href="#">
                        <template>
                          <span
                            :class="'qnt_val font-weight-bolder text-primary'"
                            >{{ summaryCerts.license }}</span
                          >
                        </template>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="4">
            <div
              :class="'card m-0 top_card card-custom gutter-b border-primary'"
            >
              <div class="card-body p-0">
                <div
                  :class="'card-rounded-bottom mid_part shadow-sm bg-light-cyan bg-front-white'"
                >
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span
                      class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                    >
                      <!-- <span class="symbol-label">
                            <span
                              :class="'svg-icon svg-icon-xl svg-icon-white'"
                            >
                              <inline-svg
                                :src="
                                  $assetURL(
                                    'media/custom-svg/engineer.svg'
                                  )
                                "
                              />
                            </span>
                          </span> -->
                      <v-icon medium class="symbol-light-danger"
                        >mdi-certificate</v-icon
                      >
                      <span
                        class="text-dark-75 font-weight-bold mt-2"
                        style="margin-left: 20px"
                      >
                        Certificates
                      </span>
                    </span>

                    <div class="d-flex flex-column text-right bTitle">
                      <a href="#">
                        <template>
                          <span
                            :class="'qnt_val font-weight-bolder text-primary'"
                            >{{ summaryCerts.certificate }}</span
                          >
                        </template>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12">
            <v-card flat class="custom-grey-border remove-border-radius">
              <DatePicker
                v-if="false"
                placeholder="Expire Date"
                :minDate="skillLicens.start_skill_date"
                v-model="skillLicens.expire_skill_date"
              />
              <v-simple-table
                class="inner-simple-table custom-border-top certs-table"
                fixed-header
              >
                <template v-slot:default>
                  <thead
                    style="
                      background-color: rgb(243, 246, 249);
                      color: rgb(36, 50, 109);
                    "
                  >
                    <tr class="custom-border-bottom">
                      <td
                        class="font-size-16 font-weight-700"
                        style="width: 15%"
                      >
                        Type
                      </td>
                      <td
                        class="font-size-16 font-weight-700"
                        style="width: 20%"
                      >
                        Name
                      </td>
                      <td
                        class="font-size-16 font-weight-700"
                        style="width: 15%"
                      >
                        Start Date
                      </td>
                      <td
                        class="font-size-16 font-weight-700"
                        style="width: 15%"
                      >
                        Expire Date
                      </td>
                      <td
                        class="font-size-16 font-weight-700"
                        style="width: 15%"
                      >
                        Reminder Date
                      </td>
                      <td
                        class="font-size-16 font-weight-700"
                        style="width: 10%"
                      >
                        Attachment
                      </td>
                      <td
                        class="font-size-16 font-weight-700"
                        style="width: 15%"
                      ></td>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="groupCertificates.length >= 0">
                      <tr
                        v-for="(row, index) in groupCertificates"
                        :key="index"
                        :class="{ 'orange_lighten-5': index % 2 == 0 }"
                      >
                        <td>
                          <v-select
                            :items="cert_types"
                            dense
                            filled
                            placeholder="Type"
                            solo
                            v-model="groupCertificates[index].type"
                            flat
                            item-color="cyan"
                            item-text="title"
                            item-value="value"
                            class="mt-0 py-0"
                          ></v-select>
                          <v-text-field
                            v-model="row.color"
                            class="d-none"
                          ></v-text-field>
                        </td>

                        <td class="">
                          <template v-if="row.type == 'skill'">
                            <v-autocomplete
                              :items="activeAllSkills"
                              dense.
                              filled
                              color="cyan"
                              item-color="cyan"
                              solo
                              flat
                              v-model.trim="row.related_value"
                              :return-object="true"
                              placeholder="Select Skill"
                              item-text="text"
                              item-value="value"
                              class="width-100 new-height mt-0"
                              hide-details
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title
                                    v-html="'No Skill(s) Found.'"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                              <template v-slot:selection="{ item }">
                                <v-list-item-action class="ma-0">
                                  <v-chip
                                    style="
                                      height: 16px;
                                      width: 16px;
                                      padding: 0;
                                    "
                                    :color="item.color"
                                  >
                                  </v-chip>
                                </v-list-item-action>
                                <v-list-item-content class="py-0">
                                  <v-list-item-title
                                    class="text-capitalize font-weight-500 font-size-16"
                                  >
                                    <template v-if="item.value == 'all'"
                                      >{{ item.text }}
                                    </template>
                                    <template v-else>{{ item.text }} </template>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                              <template v-slot:item="{ item }">
                                <v-list-item-action class="mr-0">
                                  <v-chip
                                    style="
                                      height: 16px;
                                      width: 16px;
                                      padding: 0;
                                    "
                                    :color="item.color"
                                  >
                                  </v-chip>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="text-capitalize font-weight-500 font-size-16"
                                    >{{ item.text }}</v-list-item-title
                                  >
                                </v-list-item-content>
                              </template>
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="'No Skill(s) Found.'"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </template>

                          <template v-else-if="row.type == 'license'">
                            <v-autocomplete
                              :items="activeAllLicense"
                              dense.
                              filled
                              color="cyan"
                              item-color="cyan"
                              solo
                              flat
                              :return-object="true"
                              v-model.trim="row.related_value"
                              placeholder="Select License"
                              item-text="title"
                              item-value="title"
                              class="width-100 new-height mt-0"
                              hide-details
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title
                                    v-html="'No License(s) Found.'"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                              <template v-slot:selection="{ item }">
                                <v-list-item-action class="ma-0">
                                  <v-chip
                                    style="
                                      height: 16px;
                                      width: 16px;
                                      padding: 0;
                                    "
                                    :color="item.color"
                                  >
                                  </v-chip>
                                </v-list-item-action>
                                <v-list-item-content class="py-0">
                                  <v-list-item-title
                                    class="text-capitalize font-weight-500 font-size-16"
                                  >
                                    <template v-if="item.value == 'all'"
                                      >{{ item.text }}
                                    </template>
                                    <template v-else>{{ item.text }} </template>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                              <template v-slot:item="{ item }">
                                <v-list-item-action class="mr-0">
                                  <v-chip
                                    style="
                                      height: 16px;
                                      width: 16px;
                                      padding: 0;
                                    "
                                    :color="item.color"
                                  >
                                  </v-chip>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="text-capitalize font-weight-500 font-size-16"
                                    >{{ item.text }}</v-list-item-title
                                  >
                                </v-list-item-content>
                              </template>
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="'No License(s) Found.'"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </template>
                          <template v-else>
                            <v-autocomplete
                              :items="activeAllCerts"
                              dense.
                              filled
                              color="cyan"
                              item-color="cyan"
                              solo
                              flat
                              :return-object="true"
                              v-model.trim="row.related_value"
                              placeholder="Select Certificate"
                              item-text="title"
                              item-value="title"
                              class="width-100 new-height mt-0"
                              hide-details
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title
                                    v-html="'No Certificate(s) Found.'"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                              <template v-slot:selection="{ item }">
                                <v-list-item-action class="ma-0">
                                  <v-chip
                                    style="
                                      height: 16px;
                                      width: 16px;
                                      padding: 0;
                                    "
                                    :color="item.color"
                                  >
                                  </v-chip>
                                </v-list-item-action>
                                <v-list-item-content class="py-0">
                                  <v-list-item-title
                                    class="text-capitalize font-weight-500 font-size-16"
                                  >
                                    <template v-if="item.value == 'all'"
                                      >{{ item.text }}
                                    </template>
                                    <template v-else>{{ item.text }} </template>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                              <template v-slot:item="{ item }">
                                <v-list-item-action class="mr-0">
                                  <v-chip
                                    style="
                                      height: 16px;
                                      width: 16px;
                                      padding: 0;
                                    "
                                    :color="item.color"
                                  >
                                  </v-chip>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="text-capitalize font-weight-500 font-size-16"
                                    >{{ item.text }}</v-list-item-title
                                  >
                                </v-list-item-content>
                              </template>
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="'No Certificate(s) Found.'"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                            <!-- <v-select
                                    :items="allCerts"
                                    dense
                                    filled
                                    placeholder="Certificate"
                                    solo
                                    v-model="row.related_value"
                                    flat
                                    item-color="cyan"
                                    item-text="title"
                                    item-value="title"
                                    append-outer-icon="mdi-cog"
                                    v-on:click:append-outer="manageCertificatePopup('certificate')"
                                    >
                                </v-select> -->
                          </template>
                        </td>
                        <td class="">
                          <DatePicker
                            placeholder="Start Date"
                            v-model="row.start_date"
                          />
                        </td>
                        <td class="">
                          <DatePicker
                            placeholder="Expire Date"
                            v-model="row.expire_date"
                          />
                        </td>

                        <td class="">
                          <DatePicker
                            placeholder="Reminder Date"
                            v-model="row.reminder_date"
                          />
                        </td>
                        <td class="">
                          <template v-if="row.doc_id">
                            <div class="d-flex align-center py-0">
                              <div
                                style="
                                  max-width: 50px;
                                  min-width: 50px;
                                  width: 50px;
                                "
                              >
                                <inline-svg
                                  style="max-width: 35px; width: 35px"
                                  :src="
                                    $assetURL(
                                      `media/mime/${row.doc_extension}.svg`
                                    )
                                  "
                                />
                              </div>
                              <div
                                style="
                                  max-width: 40px;
                                  min-width: 40px;
                                  width: 40px;
                                "
                              >
                                <v-btn
                                  v-on:click="removeFileIndex(index)"
                                  color="red lighten-1 white--text"
                                  icon
                                >
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </template>
                          <template v-else
                            ><FileUpload v-model="row.files" :is-single="true">
                            </FileUpload
                          ></template>
                        </td>
                        <td class="">
                          <v-icon
                            color="red"
                            size="20"
                            style="display: inline-block"
                            v-if="groupCertificates.length > 1"
                            @click="removeCertificate(index)"
                            class=""
                            >mdi-trash-can</v-icon
                          >
                          <v-icon
                            v-if="index == groupCertificates.length - 1"
                            color="cyan"
                            style="display: inline-block"
                            class="white--text ma-0 ml-2"
                            left
                            @click="addCertificate()"
                            >mdi-plus</v-icon
                          >
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td :colspan="6">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            Sorry! No Certificates(s) Found.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container white lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </v-row>
    <template>
      <!--:exist-category=""-->

      <ManageSkillsCertificates
        :d-dialog="manageSkillDialog"
        :e_designation="allSkills"
        v-on:close-dialog="manageSkillDialog = false"
        v-on:get-e-designation="getOptions"
        :certificate-type="certificateTypeForPopup"
      ></ManageSkillsCertificates>

      <ManageLicenseCertificates
        :d-dialog="manageLicenseDialog"
        :e_designation="allLicense"
        v-on:close-dialog="manageLicenseDialog = false"
        v-on:get-e-designation="getOptions"
        :certificate-type="certificateTypeForPopup"
      ></ManageLicenseCertificates>

      <ManagecertCertificates
        :d-dialog="manageCertDialog"
        :e_designation="allCerts"
        v-on:close-dialog="manageCertDialog = false"
        v-on:get-e-designation="getOptions"
        :certificate-type="certificateTypeForPopup"
      ></ManagecertCertificates>
    </template>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import { GET } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
/*   import PhoneTextField from "@/view/pages/partials/PhoneTextField"; */
import DatePicker from "@/view/components/QDatePicker.vue";
import ManageSkillsCertificates from "@/view/pages/partials/Manage-All-Certificates-TypeWise.vue";
import ManageLicenseCertificates from "@/view/pages/partials/Manage-All-Certificates-TypeWise.vue";
import ManagecertCertificates from "@/view/pages/partials/Manage-All-Certificates-TypeWise.vue";
import FileUpload from "@/view/components/app-component/FileInput.vue";

export default {
  name: "skill-license",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    updateEngineer: {
      type: Boolean,
      default: false,
    },
    engineer: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      certificateTypeForPopup: "skill",
      manageSkillDialog: false,
      manageLicenseDialog: false,
      manageCertDialog: false,
      allSkills: [],
      allLicense: [],
      allCerts: [],
      activeAllSkills: [],
      activeAllLicense: [],
      activeAllCerts: [],

      timeoutLimit: 500,
      showLicense: 0,
      showSkill: 0,
      timeout: null,
      licenseCertificate: [],
      skillCertificate: [],
      skillLicens: {
        skillCertificate: [],
        licensCertificate: [],
        start_skill_date: null,
        expire_skill_date: null,
        start_licens_date: null,
        expire_licens_date: null,
      },

      groupCertificates: [
        {
          type: "skill",
          related_value: null,
          color: null,
          start_date: null,
          expire_date: null,
          reminder_date: null,
          files: [],
        },
      ],
      cert_types: [
        {
          title: "Skill",
          value: "skill",
        },
        {
          title: "License",
          value: "license",
        },
        {
          title: "Certificate",
          value: "certificate",
        },
      ],
      fieldDescriptions: [],

      summaryCerts: {
        skill: 0,
        license: 0,
        certificate: 0,
      },
    };
  },
  watch: {
    skillLicens: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          EngineerEventBus.$emit("update:skill-licence", param);
        }, _this.timeoutLimit);
      },
    },

    /* groupCertificates: {
      deep: true,
      immediate: true,
      handler(param) {
        let skills = 0;
        let licenses = 0;
        let certs = 0;

        for (let i = 0; i < param.length; i++) {
          if (param[i].type == "skill") {
            skills += 1;
          }
          if (param[i].type == "license") {
            licenses += 1;
          }
          if (param[i].type == "certificate") {
            certs += 1;
          }
        }
        this.summaryCerts.skill = skills;
        this.summaryCerts.license = licenses;
        this.summaryCerts.certificate = certs;
      },
    }, */

    engineer() {
      this.setSkillLicense();
      this.setgroupCertificates();
    },
  },
  methods: {
    removeFileIndex(indexId = 0) {
      //if(indexId>0){
      this.groupCertificates[indexId].doc_id = 0;
      //}
    },
    manageCertificatePopup(type = "") {
      if (type == "certificate") {
        this.certificateTypeForPopup = "certificate";
        this.manageCertDialog = true;
      } else if (type == "license") {
        this.certificateTypeForPopup = "license";
        this.manageLicenseDialog = true;
      } else if (type == "skill") {
        this.certificateTypeForPopup = "skill";
        this.manageSkillDialog = true;
      }
    },
    removeCertificate(index) {
      this.groupCertificates.splice(index, 1);
    },
    addCertificate() {
      this.groupCertificates.push({
        type: "skill",
        related_value: [],
        start_date: null,
        expire_date: null,
        reminder_date: null,
        files: [],
      });
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "engineer/options",
        })
        .then(({ data }) => {
          //set skills (job types are skills)
          _this.allSkills = data?.all_job_type;
          _this.activeAllSkills = data?.job_types;
          _this.allCerts = data?.skillCertificate;
          _this.allLicense = data?.licenseCertificate;

          _this.showSkill = data.show_skills ? data.show_skills.value : 0;
          _this.showLicense = data.show_licence ? data.show_licence.value : 0;

          let filteredSkills = this.lodash.map(
            data.skillCertificate,
            function (row) {
              if (row.is_active) {
                return {
                  id: row.id,
                  title: row.text,
                  color: row.color,
                  text: row.text,
                  value: row.value.toLowerCase(),
                  is_active: row.is_active,
                };
              }
            }
          );
          _this.activeAllCerts = filteredSkills;

          let filteredLicense = this.lodash.map(
            data.licenseCertificate,
            function (row) {
              if (row.is_active) {
                return {
                  id: row.id,
                  title: row.text,
                  text: row.text,
                  color: row.color,
                  value: row.value.toLowerCase(),
                  is_active: row.is_active,
                };
              }
            }
          );
          _this.activeAllLicense = filteredLicense;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setgroupCertificates() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        //  console.log(_this.engineer.certificates );
        _this.groupCertificates = _this.engineer.certificates.length
          ? _this.engineer.certificates
          : [
              {
                type: "skill",
                related_value: null,
                color: null,
                start_date: null,
                expire_date: null,
                reminder_date: null,
                files: [],
              },
            ];
      }
    },
    setSkillLicense() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.skillLicens.licensCertificate = _this.engineer.licensCertificate;
        _this.skillLicens.skillCertificate = _this.engineer.skillCertificate;
        _this.skillLicens.start_licens_date = _this.engineer.start_licens_date;
        _this.skillLicens.expire_licens_date =
          _this.engineer.expire_licens_date;
        _this.skillLicens.start_skill_date = _this.engineer.start_skill_date;
        _this.skillLicens.expire_skill_date = _this.engineer.expire_skill_date;
        /*    _this.skillLicens.emergency_country_name =
              _this.engineer.emergency_country_name;
            _this.skillLicens.relation =
              _this.engineer.emergency_contact_relation;
            _this.skillLicens.email = _this.engineer.emergency_contact_email; */
      }
    },
  },
  components: {
    DatePicker,
    ManageSkillsCertificates,
    ManageLicenseCertificates,
    ManagecertCertificates,
    FileUpload,
  },
  mounted() {
    this.setSkillLicense();
    this.getOptions();
    this.setgroupCertificates();
  },
};
</script>
<style scoped>
.certs-table td {
  border-bottom: thin solid rgba(0, 0, 0, 0.06) !important;
}
/* .certs-table tbody td{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  } */
.engineer-create
  .v-text-field.v-text-field--solo.v-input--dense
  > .v-input__control {
  padding-bottom: 0px !important;
}
</style>
