<template>
  <Dialog :commonDialog="dDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex> Manage {{ certText(certificateType) }}</v-flex>
        <v-flex class="text-right">
          <v-btn
            depressed
            small
            class="mx-4 white--text"
            color="cyan"
            v-on:click="createContractType"
          >
            <span class="font-size-16 font-weight-600"
              >Add {{ certText(certificateType) }}</span
            >
          </v-btn>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <!-- eaecf5-->
      <v-row style="background: #ecedef">
        <v-col md="6">
          <div :class="'card m-0 top_card card-custom gutter-b border-primary'">
            <div class="card-body p-0">
              <div
                :class="'card-rounded-bottom mid_part shadow-sm bg-light-cyan bg-front-white'"
              >
                <div
                  class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                >
                  <span
                    class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                  >
                    <!-- <span class="symbol-label">
                        <span
                          :class="'svg-icon svg-icon-xl svg-icon-white'"
                        >
                          <inline-svg
                            :src="
                              $assetURL(
                                'media/custom-svg/engineer.svg'
                              )
                            "
                          />
                        </span>
                      </span> -->
                    <v-icon medium class="symbol-light-danger"
                      >mdi-certificate</v-icon
                    >
                    <span
                      class="text-dark-75 font-weight-bold mt-2"
                      style="margin-left: 20px"
                    >
                      Active
                    </span>
                  </span>

                  <div class="d-flex flex-column text-right bTitle">
                    <a href="#">
                      <template>
                        <span
                          :class="'qnt_val font-weight-bolder text-primary'"
                          >{{ summaryCerts.active }}</span
                        >
                      </template>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col md="6">
          <div :class="'card m-0 top_card card-custom gutter-b border-primary'">
            <div class="card-body p-0">
              <div
                :class="'card-rounded-bottom mid_part shadow-sm bg-light-cyan bg-front-white'"
              >
                <div
                  class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                >
                  <span
                    class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                  >
                    <!-- <span class="symbol-label">
                        <span
                          :class="'svg-icon svg-icon-xl svg-icon-white'"
                        >
                          <inline-svg
                            :src="
                              $assetURL(
                                'media/custom-svg/engineer.svg'
                              )
                            "
                          />
                        </span>
                      </span> -->
                    <v-icon medium class="symbol-light-danger"
                      >mdi-certificate</v-icon
                    >
                    <span
                      class="text-dark-75 font-weight-bold mt-2"
                      style="margin-left: 20px"
                    >
                      In Active
                    </span>
                  </span>

                  <div class="d-flex flex-column text-right bTitle">
                    <a href="#">
                      <template>
                        <span
                          :class="'qnt_val font-weight-bolder text-primary'"
                          >{{ summaryCerts.inactive }}</span
                        >
                      </template>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <v-form
          ref="ticketCollecttionForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateContractSetting"
        >
          <v-container class="py-0">
            <v-row class="py-0">
              <v-col md="12" class="py-0">
                <table class="width-100">
                  <tbody>
                    <tr v-for="(type, index) in designation" :key="index">
                      <td class="pt-3">
                        <v-text-field
                          v-model.trim="type.text"
                          dense
                          filled
                          label="Name"
                          solo
                          flat
                          class="px-4 py-1"
                          hide-details
                          :disabled="
                            pageLoading || type.is_exist ? true : false
                          "
                          :loading="pageLoading"
                          :rules="[validateRules.required(type.text, 'Name')]"
                          color="cyan"
                        ></v-text-field>
                        <div
                          class="ml-4 font-weight-bold red--text"
                          style="font-style: italic"
                          v-if="type.is_exist"
                        >
                          This field already used in other transactions.
                        </div>
                      </td>
                      <td width="50" valign="top" class="pt-3">
                        <v-switch
                          v-model.trim="type.is_active"
                          inset
                          :value="type.is_active"
                          color="cyan"
                          hide-details
                          class="mt-0"
                          :disabled="type.is_exist ? true : false"
                          @change="setSummary()"
                        ></v-switch>
                      </td>
                      <td
                        width="50"
                        v-if="designation.length > 1"
                        valign="top"
                        class="pt-4"
                      >
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="deleteContractType(index)"
                              :disabled="type.is_exist ? true : false"
                              color="deep-orange"
                              v-bind="attrs"
                              v-on="on"
                              @click="setSummary()"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Click here to delete</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </perfect-scrollbar>
    </template>
    <template v-slot:action>
      <v-btn
        depressed
        :disabled="pageLoading || !formValid"
        :loading="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateContractSetting"
      >
        Save
      </v-btn>
      <v-btn
        depressed
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close-dialog', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PATCH } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { cloneDeep } from "lodash";
export default {
  mixins: [ValidationMixin, CommonMixin],
  props: {
    dDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    e_designation: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    existCategory: {
      type: Number,
      default: null,
    },
    certificateType: {
      type: String,
      default: "skill",
    },
  },
  watch: {
    e_designation: {
      deep: true,
      immediate: true,
      handler(param) {
        this.designation = cloneDeep(param);
        this.setSummary();
      },
    },
  },
  data: () => {
    return {
      formValid: true,
      pageLoading: false,
      designation: [
        {
          text: null,
          is_active: 1,
        },
      ],
      summaryCerts: {
        active: 0,
        inactive: 0,
      },
    };
  },
  methods: {
    setSummary() {
      let param = this.designation;
      let act = 0;
      let inact = 0;
      for (let i = 0; i < param.length; i++) {
        if (
          param[i].is_active == "1" ||
          param[i].is_active == 1 ||
          param[i].is_active == true
        ) {
          act += 1;
        } else {
          inact += 1;
        }
      }
      this.summaryCerts.active = act;
      this.summaryCerts.inactive = inact;
    },
    certText(type = "") {
      if (type == "certificate") {
        return "Certificate";
      } else if (type == "license") {
        return "License";
      } else if (type == "skill") {
        return "Skill";
      }
    },
    updateContractSetting() {
      const _this = this;

      const formErrors = _this.validateForm(_this.$refs.ticketCollecttionForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.pageLoading = false;
        }
        return false;
      }

      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/certificate-type-wise",
          data: {
            e_designation: _this.designation,
            certificate_type: _this.certificateType,
          },
        })
        .then(() => {
          _this.$emit("close-dialog", true);
          _this.$emit("get-e-designation", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    createContractType() {
      this.designation.push({
        text: null,
        is_active: 1,
      });
    },
    deleteContractType(index) {
      if (this.designation.length > 1) {
        this.designation.splice(index, 1);
      }
    },
  },
  components: {
    Dialog,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
